import React, { useState, useEffect, useContext } from "react";
import "../index.scss";
import AccountSideBar from "../../../components/AccountSideBar";
import BreadCrumb from "../../../components/BreadCrumb";
import ProductCard from "../../../components/ProductCard";
import "../../Products/Products.scss";
import ApiService from "../../../api/axios";
import { ReactComponent as Logo } from "../../../assets/Heart.svg";
import Meta from "../../../components/Meta/Meta";
import { _t } from "../../../helpers/helpers";
import { tokenContext } from "../../../components/Store";
import { Link } from "react-router-dom";
import { addTosWishlist } from "../../../helpers/functions";
import { ReactComponent as Trash } from "../../../assets/Trash.svg";

const Favorites = () => {
  const [wishlists, setWishlists] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [token, setToken] = useContext(tokenContext);
  const [ischange, setIsChange] = useState(false);

  // const init = () => {
  //   // if (wishlistsIds) {
  //   ApiService.get("account/wishlist", {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }).then((resp) => {
  //     setWishlists(resp.data.data);
  //     setPageMeta(resp.data.page_meta);
  //     setBreadcrumbs(resp.data.page_meta.breadcrumbs);
  //   });
  //   // }
  // };
  useEffect(() => {
    ApiService.get("account/wishlist", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((resp) => {
      setWishlists(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, [ischange]);

  function handleDelete(id) {
    let newList = wishlists.filter((item) => item.id !== id);
    setWishlists(newList);
  }

  return (
    <div className="favorites account">
      {/* <div className="container"> */}
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.profile.info")}</h1>

            <AccountSideBar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            <div className="title">{_t("pages.favorites.title")}</div>
            {wishlists.length ? (
              <div className="productsPage">
                <div className="account-fav">
                  <div className="row">
                    {wishlists.map((wishlist) => {
                      return (
                        <div
                          className="xl-4 lg-4 md-4 sm-6 productFav"
                          key={wishlist.id}
                        >
                          <button
                            type="button"
                            className="compareDelete"
                            onClick={(e) => {
                              addTosWishlist(e, wishlist.id);
                              handleDelete(wishlist.id);
                            }}
                          >
                            <Trash />
                          </button>
                          <ProductCard product={wishlist} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="xl-12 lg-12 md-12 sm-12">
                  <div className="cartEmpty">
                    <div className="cartEptyContent">
                      <Logo />
                      <p>{_t("favorites.empty")}</p>
                      <Link
                        to="/products"
                        className="blackButton cartEmptyButton"
                      >
                        {_t("buttons.back_to_shop")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Favorites;
