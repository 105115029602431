import { createSlice } from "@reduxjs/toolkit";

function calcualteTotalPriceAndQuantity(products) {
  let total = 0;
  let quantity = 0;
  products.forEach((product) => {
    if (parseFloat(product.sale_price) == 0) {
      total += parseFloat(product.price.replace(",", "")) * product.count;
    } else {
      total += parseFloat(product.sale_price.replace(",", "")) * product.count;
    }
    quantity = Number(product.count) + Number(quantity);
  });

  return { total: total.toFixed(2), quantity };
}

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    total: "0.00",
    quantity: 0,
  },
  reducers: {
    addToCart: (state, action) => {
      let searchedProduct = state.products.filter(
        (product) => product.id == action.payload.id
      );
      searchedProduct = searchedProduct.length > 0 ? searchedProduct[0] : null;
      if (searchedProduct != null) {
        state.products = state.products.map((product) => {
          if (product.id == action.payload.id) {
            product.count = action.payload.count;
          }
          return product;
        });
      } else {
        state.products = [...state.products, { ...action.payload }];
      }
      const totalAndQuantity = calcualteTotalPriceAndQuantity(state.products);
      state.quantity = totalAndQuantity.quantity;
      state.total = totalAndQuantity.total;
    },

    initialCartData: (state, action) => {
      state.products = action.payload;
      const totalAndQuantity = calcualteTotalPriceAndQuantity(state.products);
      state.quantity = totalAndQuantity.quantity;
      state.total = totalAndQuantity.total;
    },

    deleteCart: (state, action) => {
      state.products = state.products.filter(
        (product) => product.id != action.payload
      );
      const totalAndQuantity = calcualteTotalPriceAndQuantity(state.products);
      state.quantity = totalAndQuantity.quantity;
      state.total = totalAndQuantity.total;
    },
  },
});

export const { addToCart, initialCartData, deleteCart } = cartSlice.actions;

export default cartSlice.reducer;
