import React, { useEffect, useState } from "react";
import "./News.scss";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import ApiService from "../../../api/axios";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Banner = () => {
  const [banner, setBanner] = useState([
    {
      title_image: "",
    },
  ]);
  useEffect(() => {
    ApiService.get("index/banners").then((resp) => {
      setBanner(resp.data.data);
    });
  }, []);
  return (
    <div className="News">
      <div className="container">
        <Swiper
          modules={[Pagination]}
          className="mySwiper"
          spaceBetween={10}
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            767: {
              slidesPerView: 2,
            },
          }}
        >
          {banner.map((item) => {
            return (
              <SwiperSlide>
                <div className="newsCard">
                  <a href={item.link}>
                      <LazyLoadImage
                          src={item.title_image}
                          alt="NewsImg"
                          effect="blur"
                      />
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default trackWindowScroll(Banner);
