import React, { useEffect, useState } from "react";
import "./Category.scss";

import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/arrowRight.svg";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const Category = () => {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    ApiService.get("index/categories?limit=6").then((resp) => {
      setCategory(resp.data.data);
    });
  }, []);
  return (
    <div className="Products">
      <div className="container">
        <div className="productsHeader">
          <h6 className="title">{_t("sidebar.products")}</h6>
        </div>
        <div className="row resp-none">
          {category.map((item) => {
            return (
              <Link
                to={`/products/category/${item.alias}`}
                className="categoryCard"
              >
                <LazyLoadImage
                  src={item.title_image}
                  alt="category"
                  effect="blur"
                />
                <span>{item.title}</span>
              </Link>
            );
          })}
        </div>

        <div className="row  desk-none">
          {category.map((item) => {
            return (
              <div className="xl-12 lg-12 md-12 sm-6">
                <Link
                  to={`/products/category/${item.alias}`}
                  className="categoryCard"
                >
                  <LazyLoadImage
                    src={item.title_image}
                    alt="category"
                    effect="blur"
                  />
                  <span>{item.title}</span>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="moreParent">
          <Link to="/products" className="more">
            {_t("FRONTEND.PRODUCTS_EXCERPT")}
            <Arrow />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(Category);
