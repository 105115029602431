import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import "../Authentification.scss";
import { ReactComponent as Lock } from "../../../assets/lock.svg";
import { _t } from "../../../helpers/helpers";
import InputMask from "react-input-mask";
import ApiService from "../../../api/axios";
import SimpleReactValidator from "simple-react-validator";

const CodeRegister = (props) => {
  const [code, setCode] = useState("");
  let validator = new SimpleReactValidator();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      ApiService.post("/auth/register/verify", {
        code,
        phone: params.phone.replace(" ", "+"),
      }).then((resp) => {
        localStorage.setItem("token", resp.data.token);
        localStorage.setItem("wishlist", resp.data.user.wishlist);
        localStorage.setItem("full_name", resp.data.user.fullname);
        props.history.push("/");
        window.location.reload();
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <div className="auth login">
      <div className="container">
        {/* <Breadcrumb
          link="/login"
          linkName="login"
          className="active"
          secondLink="/forget-password/"
          secondLinkName="Şifrəni unutmuşam"
        /> */}
        <div className="row">
          <div className="xl-3 lg-3 md-12 sm-12"></div>
          <div className="xl-6 lg-6 md-12 sm-12">
            <div className="authContainer">
              <div className="title">{_t("buttons_otp_code")}</div>
              <form onSubmit={handleSubmit} className="authContainerBody">
                <div className="input-group">
                  <input
                    type="number"
                    placeholder={_t("buttons_otp_code")}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <button className="blackButton">
                  <Lock />
                  {_t("buttons.send")}
                </button>
                <span className="sendToEmail">
                  {_t("conifrm.code.content")}
                </span>
              </form>
            </div>
          </div>
          <div className="xl-3 lg-3 md-12 sm-12"></div>
        </div>
      </div>
    </div>
  );
};

export default CodeRegister;
