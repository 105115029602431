import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import ApiService from "../../../api/axios";
import { ReactComponent as Lock } from "../../../assets/lock.svg";
import { _t } from "../../../helpers/helpers";
import "../Authentification.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import {Link} from "react-router-dom";
const Code = (props) => {
  const [otp, setOtp] = useState("");
  const [validator] = useState(new SimpleReactValidator({locale: localStorage.locale?localStorage.locale:'az'}));
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      ApiService.post("/auth/code/check", { otp }).then((resp) => {
        if (resp.data.success == true) {
          props.history.push("/reset-password");
        }
      });
    } else {
      validator.showMessages();
      setErrors({ ...validator.getErrorMessages() });
    }
  };
  return (
    <div className="auth login">
      <div className="container">
         <BreadCrumb
          link="/login"
          linkName="login"
          className="active"
          secondLink="/forget-password/"
          secondLinkName="Şifrəni unutmuşam"
        />
        <div className="row">
          <div className="xl-3 lg-3 md-12 sm-12"></div>
          <div className="xl-6 lg-6 md-12 sm-12">
            <div className="authContainer">
              <div className="title">{_t("buttons_otp_code")}</div>
              <form onSubmit={handleSubmit} className="authContainerBody">
                <div className="input-group phone">
                  <input
                    type="number"
                    name="otp"
                    value={otp}
                    placeholder={_t("buttons_otp_code")}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  {validator.message("otp", otp, "required|min:6")}

                </div>
                <button className="blackButton">
                  <Lock />
                  {_t("buttons.send")}
                </button>

                <span className="sendToEmail">
                  {_t("conifrm.code.content")}
                </span>
              </form>
            </div>
          </div>
          <div className="xl-3 lg-3 md-12 sm-12"></div>
        </div>
      </div>
    </div>
  );
};

export default Code;
