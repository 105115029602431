import React from "react";
import "./ProductSlide.scss";
import { ReactComponent as Arrow } from "../../../assets/arrowRight.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import ProductCard from "../../ProductCard";
import { Link } from "react-router-dom";
import { _t } from "../../../helpers/helpers";

const ProductSlide = ({ slide }) => {
  const persistScrollPosition = (id) => {
    sessionStorage.setItem("scroll-position-product-id-marker", id);
  };

  const [productMarkerId] = React.useState(
    sessionStorage.getItem("scroll-position-product-id-marker")
  );
  return (
    <div className="productSlide">
      <div className="container">
        <div className="productSlideHeader">
          {
            <Link
              to={`/products?sticker=${slide["campaign"].alias}`}
              className="title"
            >
              {slide["campaign"].title}
            </Link>
          }
          <div className="customNav">
            <button className={`${slide["campaign"].alias}-custom_prev1 custom_prev`}>
              <Arrow />
            </button>
            <button className={`${slide["campaign"].alias}-custom_next1 custom_next`}>
              <Arrow />
            </button>
          </div>
        </div>

        <Swiper
          navigation={{
            nextEl: `.${slide["campaign"].alias}-custom_next1`,
            prevEl: `.${slide["campaign"].alias}-custom_prev1`,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          slidesPerView={3}
          spaceBetween={16}
          breakpoints={{
            320: {
              slidesPerView: 1.4,
            },
            767: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
          }}
        >
          <>
            {slide["products"].slice(0,7).map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <ProductCard
                    product={item}
                    onSelect={persistScrollPosition}
                    productMarkerId={productMarkerId}
                  />
                </SwiperSlide>
              );
            })}
          </>
        </Swiper>

        <Link
          to={`/products?sticker=${slide["campaign"].alias}`}
          className="moreParent"
        >
          <div className="more">
            {_t("FRONTEND.PRODUCTS_EXCERPT")}
            <Arrow />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductSlide;
