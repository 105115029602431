import React, { useState, useEffect, useContext } from "react";

import "./Products.scss";
import { ReactComponent as FilterAZ } from "../../assets/filter.svg";
import { ReactComponent as FilterZA } from "../../assets/filterza.svg";
import { ReactComponent as FilterStar } from "../../assets/Star.svg";
import { ReactComponent as Filter19 } from "../../assets/Filter19.svg";
import { ReactComponent as Filter91 } from "../../assets/filter91.svg";
import { ReactComponent as Manat } from "../../assets/manatBlack.svg";
import { ReactComponent as Search } from "../../assets/Search.svg";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import { ReactComponent as Trash } from "../../assets/Trash.svg";
// import { ReactComponent as Prev } from "../../assets/arrowRight.svg";
import Select from "react-select";
import ProductCard from "../../components/ProductCard";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import { _t } from "../../helpers/helpers";
import ProductEmpty from "../../assets/productEmpty.png";
import { Link, useHistory } from "react-router-dom";
import {
  isLoadingContext,
  showCountModalContext,
} from "../../components/Store";
import Pagination from "rc-pagination";

const params = (item) => new URLSearchParams(window.location.search).get(item);

const ProductsCategory = (props) => {
  const [isLoadingNow, setIsLoadingNow] = useContext(isLoadingContext);

  const [state, setState] = useState("xl-3 lg-3 md-4 sm-6 grid-four");
  const [category, setCategory] = useState("");
  const [setGrid, setSetGrid] = useState("");
  const [setGridTwo, setSetGridTwo] = useState("");
  const [setGridFour, setSetGridFour] = useState("active");
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState({
    order_by: "position",
    direction: "DESC",
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [checked, setChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pageMeta, setPageMeta] = useState({
    title: "",
  });
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(24);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(parseInt(params("currentPage")) || 1);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  useEffect(() => {
  }, [current]);

  const options = [
    {
      value: { order_by: "title", direction: "ASC" },
      label: (
        <div className="filterInner">
          <FilterAZ />
          {_t("filter.from_a_to_z")}
        </div>
      ),
    },
    {
      value: { order_by: "title", direction: "DESC" },
      label: (
        <div className="filterInner">
          <FilterZA />
          {_t("filter.from_z_to_a")}
        </div>
      ),
    },
    {
      value: { order_by: "rating", direction: "DESC" },
      label: (
        <div className="filterInner filterInnerP">
          <FilterStar />
          {_t("filter.popularity")}
        </div>
      ),
    },
    {
      value: { order_by: "price", direction: "ASC" },
      label: (
        <div className="filterInner">
          <Filter19 />
          {_t("filter.cheap_to_expensive")}
        </div>
      ),
    },
    {
      value: { order_by: "price", direction: "DESC" },
      label: (
        <div className="filterInner">
          <Filter91 />
          {_t("filter.expensive_to_cheap")}
        </div>
      ),
    },
  ];
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottomRightRadius: "1.6rem",
      borderBottomLeftRadius: "1.6rem",
      overflow: "hidden",
      width: "100%",
      backgroundColor: "#F2F1F1",
    }),
    control: () => ({
      backgroundColor: "#F2F1F1",
      width: "28rem",
      height: "4.8rem",
      display: "flex",
      alignItems: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      color: "#000000",
      borderTopRightRadius: "1.5rem",
      borderTopLeftRadius: "1.5rem",
    }),
    option: (styles, state) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "1.2rem",
        lineHeight: "2.2rem",
        color: "#00000080",
        cursor: "pointer",
      };
    },
    singleValue: (provided, state) => ({
      display: "flex",
      alignItems: "center",
    }),
  };
  const [filters, setFilters] = useState({
    categorys: [],
    brands: [],
    max_price: 100000,
    min_price: 0,
  });
  // brands
  const [searchBrand, setSearchBrands] = useState("");

  const onchangeBrand = (e) => {
    setSearchBrands(e.target.value);
  };

  const handleFilter = (e) => {
    let fil = filters;
    setChecked(!checked);
    if (e.target.checked) {
      fil[e.target.name].push(e.target.value);
      setFilters(fil);
    } else {
      let index = fil[e.target.name].indexOf(e.target.value);
      if (index !== -1) {
        fil[e.target.name].splice(index, 1);
      }
    }
    init(filters, sort, current);
  };

  const init = (filters, sort, page, infinite = false) => {
    setLoading(true);
    setPage(page);
    setPage(1);
    if (!infinite) {
      setPage(1);
    }
    let url = new URLSearchParams(props.location.search);
    let fields = {
      brands: filters.brands,
      categorys: filters.categorys,
      subcategories: filters.subcategories,
      stickers: filters.stickers,
      order_by: sort.order_by,
      direction: sort.direction,
      min_price: filters.min_price,
      max_price: filters.max_price,
      price: filters.price,
      // page: page,
      keyword: url.get("keyword"),
    };

    let urlProduct = `/products/list/${props.match.params.category}/${props.match.params.subcategory}?page=${current}`;
    ApiService.get(urlProduct, {
      params: fields,
    })
      .then((resp) => {
        if (infinite) {
          setProducts([...products, ...resp.data.data]);
        } else {
          setProducts(resp.data.data);
          setTotalCount(resp.data.count);
        }
        setPageMeta(resp.data.page_meta);
        setBreadcrumbs(resp.data.page_meta.breadcrumbs);
        setTotal(resp.data.count);
        setIsLoadingNow(false);
        setMaxPrice(resp.data.max_price);
        setMinPrice(resp.data.min_price);
      })
      .finally(() => {
        setRefresh(true);
      });
  };

  const handleFilterSingle = (e) => {
    let fil = filters;
    setChecked(!checked);
    fil[e.target.name] = e.target.value;
    init(filters, sort, current);
  };
  const deleteFilterElement = (e, value, array) => {
    document.getElementById(value).style.display = "none";
    let fil = filters;
    let index = fil[array].indexOf(value);
    if (index !== -1) {
      fil[array].splice(index, 1);
    }
    init(filters, sort, page);
  };

  const filteredBrand = !searchBrand
    ? brands
    : brands.filter((brand) =>
        brand.title.toLowerCase().includes(searchBrand.toLowerCase())
      );

  const sortProducts = (e) => {
    setSort(e.value);
    init(filters, e.value, current);
  };

  const gridOne = () => {
    setState("xl-12 lg-12 md-12 sm-12 grid-row");
    setSetGrid(setGrid === "" ? "active" : "active");
    setSetGridTwo(setGridTwo === "active" ? "" : "");
    setSetGridFour(setGridFour === "active" ? "" : "");
  };

  useEffect(() => {
    setCurrent(parseInt(params("currentPage")) || 1);
  }, [props.match.params.category, props.match.params.subcategory]);

  useEffect(() => {
    setCategory(props.match.params.category);

    let url = new URLSearchParams(props.location.search);
    let arrays = ["category", "sticker", "brand"];
    let fil = filters;
    setFilters({
      categorys: [],
      brands: [],
      keyword: "",
      price: null,
    });
    setPage(1);
    arrays.map((value) => {
      if (url.get(value)) {
        fil[`${value}s`] = [];
        fil[`${value}s`].push(url.get(value));
        setFilters(fil);
      }
    });
    init(filters, sort, page);

    ApiService.get(
      `/products/filters/category/${props.match.params.category}/${props.match.params.subcategory}`
    ).then((res) => {
      setBrands(res.data.data.brands);
      setSubCategories(res.data.data.subcategories);
      setIsLoadingNow(false);
    });
  }, [
    props.match.params.category,
    props.location.search,
    props.match.params.subcategory,
    current,
  ]);

  const gridTwo = () => {
    setState("xl-4 lg-4 md-4 sm-12 ");
    setSetGridTwo(setGridTwo === "" ? "active" : "active");
    setSetGrid(setGrid === "active" ? "" : "");
    setSetGridFour(setGridFour === "active" ? "" : "");
  };

  const gridFour = () => {
    setState("xl-3 lg-3 md-4 sm-6 grid-four");
    setSetGridFour(setGridFour === "" ? "active" : "active");
    setSetGridTwo(setGridTwo === "active" ? "" : "");
    setSetGrid(setGrid === "active" ? "" : "");
  };

  const [isFilter, setIsFilter] = useState(false);
  const openFilter = () => {
    setIsFilter(!isFilter);
    document.body.classList.add("body-overflow");
  };
  const closeFilter = () => {
    setIsFilter(false);
    setPage(1);
    document.body.classList.remove("body-overflow");
  };

  const handlePrice = (e) => {
    let fil = filters;
    fil.min_price = e.target.value.split(",")[0];
    fil.max_price = e.target.value.split(",")[1];
    setFilters(fil);
    init(filters, sort, current);
  };

  const handleMinPrice = (e) => {
    let fil = filters;
    fil.min_price = e.target.value;
    setFilters(fil);
    init(filters, sort, current);
  };
  const handleMaxPrice = (e) => {
    let fil = filters;
    fil.max_price = e.target.value;
    setFilters(fil);
    init(filters, sort, current);
  };

  const [isFilterCategory, setIsFilterCategory] = useState(false);
  const [isFilterPrice, setIsFilterPrice] = useState(false);
  const [isFilterBrand, setIsFilterBrand] = useState(false);
  const [isFilterItem, setIsFilterItem] = useState(false);
  const history = useHistory();
  // const restorationRef = useRef(null);

  const filterAccordionCategory = () => {
    setIsFilterCategory(!isFilterCategory);
    setIsFilterPrice(false);
    setIsFilterBrand(false);
    setIsFilterItem(false);
  };
  const filterAccordionPrice = () => {
    setIsFilterPrice(!isFilterPrice);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterItem(false);
  };
  const filterAccordionBrand = () => {
    setIsFilterBrand(!isFilterBrand);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterItem(false);
  };
  // const filterAccordionItem = () => {
  //   setIsFilterItem(!isFilterItem);
  //   setIsFilterBrand(false);
  //   setIsFilterPrice(false);
  //   setIsFilterCategory(false);
  // };
  // const [limit, setLimit] = useState(6);
  const handleResetFilter = (e) => {
    e.preventDefault();
    init();
  };
  const persistScrollPosition = (id) => {
    sessionStorage.setItem("scroll-position-product-id-marker", id);
  };

  const [productMarkerId] = React.useState(
    sessionStorage.getItem("scroll-position-product-id-marker")
  );

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(products.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    // return products.slice((current - 1) * pageSize, current * pageSize);
    return products;
  };

  const PaginationChange = (page, pageSize) => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams();
    searchParams.set("currentPage", page);
    history.push({ search: searchParams.toString() });
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };

  return (
    <div className="productsPage">
      <Meta meta={pageMeta} />

      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="productsHeader">
          <h1 className="productHeaderTitle">
            {pageMeta.title}
            <span>
              ({total} {_t("sidebar.product")})
            </span>
          </h1>
          <div className="productHeaderFilter">
            <button
              className={`productHeaderGrid ${setGrid}`}
              onClick={gridOne}
            >
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.8332 7.49999H2.16688C2.16993 5.00172 2.22006 3.70397 3.04537 2.87867C3.92405 1.99999 5.33826 1.99999 8.16669 1.99999H17.8334C20.6618 1.99999 22.076 1.99999 22.9547 2.87867C23.78 3.70397 23.8301 5.00172 23.8332 7.49999ZM2.16669 9.49999H23.8334V14.5H2.16669V9.49999ZM2.16688 16.5C2.16993 18.9983 2.22006 20.296 3.04537 21.1213C3.92405 22 5.33826 22 8.16668 22H17.8334C20.6618 22 22.076 22 22.9547 21.1213C23.78 20.296 23.8301 18.9983 23.8332 16.5H2.16688Z"
                    fill="url(#paint0_linear_288_14899)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_288_14899"
                    x1="13"
                    y1="1.99999"
                    x2="13"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#121212" />
                    <stop offset="1" stopColor="#202020" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
            <button
              className={`productHeaderGrid ${setGridTwo}`}
              onClick={gridTwo}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 2.24999C3.48122 2.24999 2.25 3.48121 2.25 4.99999C2.25 6.51878 3.48122 7.74999 5 7.74999C6.51878 7.74999 7.75 6.51878 7.75 4.99999C7.75 3.48121 6.51878 2.24999 5 2.24999ZM5 9.24999C3.48122 9.24999 2.25 10.4812 2.25 12C2.25 13.5188 3.48122 14.75 5 14.75C6.51878 14.75 7.75 13.5188 7.75 12C7.75 10.4812 6.51878 9.24999 5 9.24999ZM2.25 19C2.25 17.4812 3.48122 16.25 5 16.25C6.51878 16.25 7.75 17.4812 7.75 19C7.75 20.5188 6.51878 21.75 5 21.75C3.48122 21.75 2.25 20.5188 2.25 19ZM12 2.24999C10.4812 2.24999 9.25 3.48121 9.25 4.99999C9.25 6.51878 10.4812 7.74999 12 7.74999C13.5188 7.74999 14.75 6.51878 14.75 4.99999C14.75 3.48121 13.5188 2.24999 12 2.24999ZM9.25 12C9.25 10.4812 10.4812 9.24999 12 9.24999C13.5188 9.24999 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12ZM12 16.25C10.4812 16.25 9.25 17.4812 9.25 19C9.25 20.5188 10.4812 21.75 12 21.75C13.5188 21.75 14.75 20.5188 14.75 19C14.75 17.4812 13.5188 16.25 12 16.25ZM16.25 4.99999C16.25 3.48121 17.4812 2.24999 19 2.24999C20.5188 2.24999 21.75 3.48121 21.75 4.99999C21.75 6.51878 20.5188 7.74999 19 7.74999C17.4812 7.74999 16.25 6.51878 16.25 4.99999ZM19 9.24999C17.4812 9.24999 16.25 10.4812 16.25 12C16.25 13.5188 17.4812 14.75 19 14.75C20.5188 14.75 21.75 13.5188 21.75 12C21.75 10.4812 20.5188 9.24999 19 9.24999ZM16.25 19C16.25 17.4812 17.4812 16.25 19 16.25C20.5188 16.25 21.75 17.4812 21.75 19C21.75 20.5188 20.5188 21.75 19 21.75C17.4812 21.75 16.25 20.5188 16.25 19Z"
                  fill="#004CEB"
                />
              </svg>
            </button>
            <button
                className={`productHeaderGrid ${setGridFour}`}
                style={{paddingLeft: 0}}
                onClick={gridFour}
            >
              <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{width: '3.4rem'}}
              >
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5 2.24999C3.48122 2.24999 2.25 3.48121 2.25 4.99999C2.25 6.51878 3.48122 7.74999 5 7.74999C6.51878 7.74999 7.75 6.51878 7.75 4.99999C7.75 3.48121 6.51878 2.24999 5 2.24999ZM5 9.24999C3.48122 9.24999 2.25 10.4812 2.25 12C2.25 13.5188 3.48122 14.75 5 14.75C6.51878 14.75 7.75 13.5188 7.75 12C7.75 10.4812 6.51878 9.24999 5 9.24999ZM2.25 19C2.25 17.4812 3.48122 16.25 5 16.25C6.51878 16.25 7.75 17.4812 7.75 19C7.75 20.5188 6.51878 21.75 5 21.75C3.48122 21.75 2.25 20.5188 2.25 19ZM12 2.24999C10.4812 2.24999 9.25 3.48121 9.25 4.99999C9.25 6.51878 10.4812 7.74999 12 7.74999C13.5188 7.74999 14.75 6.51878 14.75 4.99999C14.75 3.48121 13.5188 2.24999 12 2.24999ZM9.25 12C9.25 10.4812 10.4812 9.24999 12 9.24999C13.5188 9.24999 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12ZM12 16.25C10.4812 16.25 9.25 17.4812 9.25 19C9.25 20.5188 10.4812 21.75 12 21.75C13.5188 21.75 14.75 20.5188 14.75 19C14.75 17.4812 13.5188 16.25 12 16.25ZM16.25 4.99999C16.25 3.48121 17.4812 2.24999 19 2.24999C20.5188 2.24999 21.75 3.48121 21.75 4.99999C21.75 6.51878 20.5188 7.74999 19 7.74999C17.4812 7.74999 16.25 6.51878 16.25 4.99999ZM19 9.24999C17.4812 9.24999 16.25 10.4812 16.25 12C16.25 13.5188 17.4812 14.75 19 14.75C20.5188 14.75 21.75 13.5188 21.75 12C21.75 10.4812 20.5188 9.24999 19 9.24999ZM16.25 19C16.25 17.4812 17.4812 16.25 19 16.25C20.5188 16.25 21.75 17.4812 21.75 19C21.75 20.5188 20.5188 21.75 19 21.75C17.4812 21.75 16.25 20.5188 16.25 19Z"
                      fill="#004CEB"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M 26.113 2.203 C 24.594 2.203 23.363 3.435 23.363 4.953 C 23.363 6.472 24.594 7.703 26.113 7.703 C 27.632 7.703 28.863 6.472 28.863 4.953 C 28.863 3.435 27.632 2.203 26.113 2.203 Z M 26.113 9.203 C 24.594 9.203 23.363 10.435 23.363 11.953 C 23.363 13.472 24.594 14.703 26.113 14.703 C 27.632 14.703 28.863 13.472 28.863 11.953 C 28.863 10.435 27.632 9.203 26.113 9.203 Z M 23.363 18.953 C 23.363 17.435 24.594 16.203 26.113 16.203 C 27.632 16.203 28.863 17.435 28.863 18.953 C 28.863 20.472 27.632 21.703 26.113 21.703 C 24.594 21.703 23.363 20.472 23.363 18.953 Z"
                      fill="#004CEB"/>

              </svg>
            </button>
            <Select
              classNamePrefix="filter"
              options={options}
              onChange={(e) => sortProducts(e)}
              components={{ IndicatorSeparator: () => null }}
              defaultValue={options[0]}
              styles={customStyles}
            />
            <button className="filterOpen" onClick={openFilter}>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 2.4375C6 2.02329 5.66421 1.6875 5.25 1.6875C4.83579 1.6875 4.5 2.02329 4.5 2.4375V4.78201C3.20608 5.11505 2.25 6.28962 2.25 7.6875C2.25 9.34435 3.59315 10.6875 5.25 10.6875C6.90685 10.6875 8.25 9.34435 8.25 7.6875C8.25 6.28962 7.29392 5.11505 6 4.78201V2.4375ZM3.75 7.6875C3.75 6.85907 4.42157 6.1875 5.25 6.1875C6.07843 6.1875 6.75 6.85907 6.75 7.6875C6.75 8.51593 6.07843 9.1875 5.25 9.1875C4.42157 9.1875 3.75 8.51593 3.75 7.6875ZM6 12.1875C6 11.7733 5.66421 11.4375 5.25 11.4375C4.83579 11.4375 4.5 11.7733 4.5 12.1875V15.9375C4.5 16.3517 4.83579 16.6875 5.25 16.6875C5.66421 16.6875 6 16.3517 6 15.9375V12.1875ZM13.5 2.4375C13.5 2.02329 13.1642 1.6875 12.75 1.6875C12.3358 1.6875 12 2.02329 12 2.4375V6.1875C12 6.60171 12.3358 6.9375 12.75 6.9375C13.1642 6.9375 13.5 6.60171 13.5 6.1875V2.4375ZM12.75 12.1875C13.5784 12.1875 14.25 11.5159 14.25 10.6875C14.25 9.85907 13.5784 9.1875 12.75 9.1875C11.9216 9.1875 11.25 9.85907 11.25 10.6875C11.25 11.5159 11.9216 12.1875 12.75 12.1875ZM13.5 15.9375V13.593C14.7939 13.26 15.75 12.0854 15.75 10.6875C15.75 9.03065 14.4069 7.6875 12.75 7.6875C11.0931 7.6875 9.75 9.03065 9.75 10.6875C9.75 12.0854 10.7061 13.26 12 13.593V15.9375C12 16.3517 12.3358 16.6875 12.75 16.6875C13.1642 16.6875 13.5 16.3517 13.5 15.9375Z"
                  fill="#22282F"
                />
              </svg>
              {_t("table.content.filters")}
            </button>
          </div>
        </div>
        <div className="row productsParent">
          <div className="xl-3 lg-3 md-3 sm-12">
            <div className="productParentHead">
              <h2>{_t("table.content.filters")}</h2>
              <button type="button" onClick={handleResetFilter}>
                <Trash />
                {_t("buttons.filter.remove")}
              </button>
            </div>
            <div
              className={isFilter ? "productsFilter active" : "productsFilter"}
            >
              <div className="mobileFilterHead">
                <div className="mobileCloseFilter" onClick={closeFilter}>
                  <svg
                    width="41"
                    height="42"
                    viewBox="0 0 41 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.54289 9.55071C9.93342 9.16018 10.5666 9.16018 10.9571 9.55071L20.5 19.0936L30.0429 9.55071C30.4334 9.16018 31.0666 9.16018 31.4571 9.55071C31.8476 9.94123 31.8476 10.5744 31.4571 10.9649L21.9142 20.5078L31.4571 30.0507C31.8476 30.4412 31.8476 31.0744 31.4571 31.4649C31.0666 31.8554 30.4334 31.8554 30.0429 31.4649L20.5 21.922L10.9571 31.4649C10.5666 31.8554 9.93342 31.8554 9.54289 31.4649C9.15237 31.0744 9.15237 30.4412 9.54289 30.0507L19.0858 20.5078L9.54289 10.9649C9.15237 10.5744 9.15237 9.94123 9.54289 9.55071Z"
                      fill="#121212"
                    />
                  </svg>
                </div>
                <div className="title-filter">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.0666 3.50001C9.0666 3.00295 8.66366 2.60001 8.1666 2.60001C7.66954 2.60001 7.2666 3.00295 7.2666 3.50001V7.3588C5.2682 7.77417 3.7666 9.54506 3.7666 11.6667C3.7666 14.0967 5.73655 16.0667 8.1666 16.0667C10.5967 16.0667 12.5666 14.0967 12.5666 11.6667C12.5666 9.54506 11.065 7.77417 9.0666 7.3588V3.50001ZM8.1666 9.06667L8.16741 9.06667C9.60298 9.06711 10.7666 10.231 10.7666 11.6667C10.7666 13.1026 9.60254 14.2667 8.1666 14.2667C6.73066 14.2667 5.5666 13.1026 5.5666 11.6667C5.5666 10.2307 6.73066 9.06667 8.1666 9.06667ZM9.0666 18.6667C9.0666 18.1696 8.66366 17.7667 8.1666 17.7667C7.66954 17.7667 7.2666 18.1696 7.2666 18.6667V24.5C7.2666 24.9971 7.66954 25.4 8.1666 25.4C8.66366 25.4 9.0666 24.9971 9.0666 24.5V18.6667ZM20.7333 3.50001C20.7333 3.00295 20.3303 2.60001 19.8333 2.60001C19.3362 2.60001 18.9333 3.00295 18.9333 3.50001V9.33334C18.9333 9.8304 19.3362 10.2333 19.8333 10.2333C20.3303 10.2333 20.7333 9.8304 20.7333 9.33334V3.50001ZM19.8333 18.9333C21.2692 18.9333 22.4333 17.7693 22.4333 16.3333C22.4333 14.8974 21.2692 13.7333 19.8333 13.7333C18.3973 13.7333 17.2333 14.8974 17.2333 16.3333C17.2333 17.7693 18.3973 18.9333 19.8333 18.9333ZM20.7333 24.5V20.6412C22.7317 20.2258 24.2333 18.4549 24.2333 16.3333C24.2333 13.9033 22.2633 11.9333 19.8333 11.9333C17.4032 11.9333 15.4333 13.9033 15.4333 16.3333C15.4333 18.455 16.9349 20.2258 18.9333 20.6412V24.5C18.9333 24.9971 19.3362 25.4 19.8333 25.4C20.3303 25.4 20.7333 24.9971 20.7333 24.5Z"
                      fill="#FF0404"
                    />
                  </svg>
                  {_t("table.content.filters")}
                </div>
                <div className="clear">{_t("buttons.remove")}</div>
              </div>
              <form className="productFilterSection">
                <div
                  className={
                    isFilterCategory
                      ? "productFilterSectionChild active"
                      : "productFilterSectionChild"
                  }
                >
                  <h5 className="title" onClick={filterAccordionCategory}>
                    {_t("sidebar.categories")}
                    <Arrow />
                  </h5>
                  <div className="filter-content categroyFilter">
                    {subCategories.map((category) => {
                      return (
                        <>
                          {category.count <= 0 ? null : (
                            <label className="filterCheckbox">
                              <Link
                                to={`/products/category/${props.match.params.category}/${category.alias}`}
                                onClick={closeFilter}
                                // onClick={(e) => handleCategory(e, category.alias)}
                              >{`${category.title}`}</Link>
                            </label>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>

                <div
                  className={
                    isFilterBrand
                      ? "productFilterSectionChild active"
                      : "productFilterSectionChild"
                  }
                >
                  <h5 className="title" onClick={filterAccordionBrand}>
                    {_t("sidebar.brands")}
                    <Arrow />
                  </h5>
                  <div className="filterSearch">
                    <input
                      type="text"
                      placeholder={_t("input_groupSearch_placeholder")}
                      onChange={onchangeBrand}
                    />
                    <button onClick={closeFilter}>
                      <Search />
                    </button>
                  </div>
                  <div className="filter-content">
                    {filteredBrand.map((brand) => {
                      return (
                        <>
                          {brand.count !== 0 || null ? (
                            <label
                              htmlFor=""
                              className="filterCheckbox"
                              key={brand.id}
                              onClick={closeFilter}
                            >
                              <span>
                                {brand.title} ({brand.count})
                              </span>
                              <input
                                type="checkbox"
                                name="brands"
                                value={brand.alias}
                                onChange={(e) => handleFilter(e)}
                                id={brand.id}
                              />
                              <span className="checkmark"></span>
                            </label>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>

                <div
                  className={
                    isFilterPrice
                      ? "productFilterSectionChild active"
                      : "productFilterSectionChild"
                  }
                >
                  <h5 className="title" onClick={filterAccordionPrice}>
                    {_t("title.price")} <Arrow />
                  </h5>
                  <div className="filterInputsection">
                    <div className="filterInputsSectionChild">
                      <input
                        type="number"
                        onChange={(e) => handleMinPrice(e)}
                        placeholder={minPrice}
                        min={minPrice}
                      />
                      <span>–</span>
                      <input
                        type="number"
                        onChange={(e) => handleMaxPrice(e)}
                        placeholder={maxPrice}
                        max={maxPrice}
                      />
                    </div>
                    <button type="button">
                      <Search />
                    </button>
                  </div>
                  <div className="filter-content">
                    <label
                      htmlFor=""
                      className="filterRadio"
                      onClick={closeFilter}
                    >
                      <span>
                        0 – 50 <Manat />
                      </span>
                      <input
                        type="radio"
                        name="radio"
                        value="0,50"
                        id=""
                        // onChange={(e) => changePrice(e)}
                        onChange={(e) => handlePrice(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      htmlFor=""
                      className="filterRadio"
                      onClick={closeFilter}
                    >
                      <span>
                        50 – 100 <Manat />
                      </span>
                      <input
                        type="radio"
                        name="radio"
                        id=""
                        value="50,100"
                        onChange={(e) => handlePrice(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      htmlFor=""
                      className="filterRadio"
                      onClick={closeFilter}
                    >
                      <span>
                        100 – 200 <Manat />
                      </span>
                      <input
                        type="radio"
                        name="radio"
                        id=""
                        value="100,200"
                        onChange={(e) => handlePrice(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      htmlFor=""
                      className="filterRadio"
                      onClick={closeFilter}
                    >
                      <span>
                        200 <Manat /> – {_t("input.much.more")}
                      </span>
                      <input
                        type="radio"
                        name="radio"
                        id=""
                        value="200,1000000"
                        onChange={(e) => handlePrice(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      htmlFor=""
                      className="filterRadio"
                      onClick={closeFilter}
                    >
                      <span>{_t("title.sidebar.all")}</span>
                      <input
                        type="radio"
                        name="radio"
                        value="0,10000000"
                        id=""
                        onChange={(e) => handlePrice(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="xl-12 lg-12 md-12 sm-12">
              <div className="row">
                <div className="xl-12 lg-12 md-12 sm-12">
                  {/* <div className="productTop">{pageMeta.title}</div> */}
                  {isLoadingNow == false ? (
                    <>
                      {products == 0 ? (
                        <div className="xl-12 lg-12 md-12 sm-12">
                          <div className="cartEmpty">
                            <div className="cartEptyContent">
                              <img src={ProductEmpty} alt="empty" />
                              {/* <p>{_t("not.found.product")}</p> */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        // <InfiniteScroll
                        //   dataLength={products.length}
                        //   next={() => init(filters, sort, page + 1, true)}
                        //   hasMore={loading}
                        //   className={"row"}
                        // >
                        <div className="row">
                          {getData(current, size).map((productItem) => {
                            return (
                              <div
                                key={productItem.id}
                                className={state}
                              >
                                <ProductCard
                                  product={productItem}
                                  onSelect={persistScrollPosition}
                                  productMarkerId={productMarkerId}
                                />
                              </div>
                            );
                          })}
                          {/* </InfiniteScroll> */}
                        </div>
                      )}
                      <Pagination
                        className="pagination-data"
                        onChange={PaginationChange}
                        total={totalCount}
                        current={current}
                        pageSize={size}
                        showSizeChanger={false}
                        itemRender={PrevNextArrow}
                        onShowSizeChange={PerPageChange}
                      />
                    </>
                  ) : (
                    <div className="xl-12 lg-12 md-12 sm-12">
                      <div className="cartEmpty">
                        <div className="cartEptyContent">
                          <img src={ProductEmpty} alt="empty" />
                          <p>{_t("site_content.loading")}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {pageMeta.content !== 0 || null || "" ? ( */}
      <div className="seoParent">
        <div className="container">
          <div
            className="seoChild"
            // dangerouslySetInnerHTML={{
            //   __html: pageMeta.content,
            // }}
          >
            <h2>{pageMeta.title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: pageMeta.description,
              }}
            ></p>
          </div>
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
};
export default ProductsCategory;
