import React, { useEffect, useState } from "react";
import "./ProductSlide.scss";
import { ReactComponent as Arrow } from "../../../assets/arrowRight.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import ProductCard from "../../ProductCard";
import { Link } from "react-router-dom";
import { _t } from "../../../helpers/helpers";
import ApiService from "../../../api/axios";
const ProductSlide = () => {
  const [saleProducts, setSaleProducts] = useState([]);

  useEffect(() => {
    ApiService.get("index/sale_products").then((res) => {
      setSaleProducts(res.data.data);
    });
  }, []);
  const persistScrollPosition = (id) => {
    sessionStorage.setItem("scroll-position-product-id-marker", id);
  };

  const [productMarkerId] = React.useState(
    sessionStorage.getItem("scroll-position-product-id-marker")
  );
  return (
    <div className="productSlide">
      {saleProducts.length !== 0 ? (
        <div className="container">
          <div className="productSlideHeader">
            {
              <Link
                to={`/products?sale=in_sale`}
                className="title"
              >
                {_t("sidebar.sale.products")}
              </Link>
            }
            <div className="customNav">
              <button className="custom_prev custom_prev1">
                <Arrow />
              </button>
              <button className="custom_next custom_next1">
                <Arrow />
              </button>
            </div>
          </div>

          <Swiper
            navigation={{
              nextEl: ".custom_next1",
              prevEl: ".custom_prev1",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={3}
            spaceBetween={16}
            breakpoints={{
              320: {
                slidesPerView: 1.4,
              },
              767: {
                slidesPerView: 4,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            <>
              {saleProducts.slice(0,7).map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <ProductCard
                      product={item}
                      onSelect={persistScrollPosition}
                      productMarkerId={productMarkerId}
                    />
                  </SwiperSlide>
                );
              })}
            </>
          </Swiper>
          <Link to={`/products?sale=in_sale`} className="moreParent">
            <div className="more">
              {_t("FRONTEND.PRODUCTS_EXCERPT")}
              <Arrow />
            </div>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default ProductSlide;
