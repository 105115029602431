import React, { useState, useEffect } from "react";
import "../index.scss";
import SupportSidebar from "../../../components/SupportSidebar";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import { _t } from "../../../helpers/helpers";
const Delivery = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [delivery, setDelivery] = useState({});
  useEffect(() => {
    ApiService.get("pageByUrl?url=delivery-payment").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setDelivery(resp.data.data);
    });
  }, []);
  return (
    <div className="support">
      {/* <div className="container"> */}
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.support")}</h1>
            <SupportSidebar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            <div className="title">{delivery.title}</div>
            <div className="DeliveryList">
              <h2 className="title">{delivery.description}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: delivery.content,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Delivery;
