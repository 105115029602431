import React, {useEffect, useState} from "react";
import "../index.scss";
import AccountSideBar from "../../../components/AccountSideBar";
import InputMask from "react-input-mask";
import {ReactComponent as Tick} from "../../../assets/tick.svg";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import {_t} from "../../../helpers/helpers";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";

const PersonalInfo = () => {
    const [pageMeta, setPageMeta] = useState({});
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [full_name, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [validator] = useState(new SimpleReactValidator({locale: localStorage.locale?localStorage.locale:'az'}));
    const [errors, setErrors] = useState({});


    useEffect(() => {
        ApiService.get("account").then((resp) => {
            setPageMeta(resp.data.page_meta);
            setBreadcrumbs(resp.data.page_meta.breadcrumbs);
            setFullName(resp.data.user.fullname);
            setEmail(resp.data.user.email);
            setPhone(resp.data.user.phone);
        });
    }, []);
    const handleForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {

            ApiService.put("account", {full_name, email, phone}).then((resp) => {
                setFullName(resp.data.user.fullname);
                setEmail(resp.data.user.email);
                setPhone(resp.data.user.phone);
                Swal.fire(_t("success.title"), _t("success.message"), "success");
            });
        }else {
            validator.showMessages();
            setErrors({ ...validator.getErrorMessages() });
        }
    };
    return (
        <div className="personalInfo account">
            <Meta meta={pageMeta}/>

            {/* <div className="container"> */}
            <div className="accoutRow">
                <div className="accountSidebar">
                    <div className="accountSideBarChild">
                        <BreadCrumb breadcrumbs={breadcrumbs}/>
                        <h1>{_t("title.profile.info")}</h1>
                        <AccountSideBar/>
                    </div>
                </div>
                <div className="AccountRight">
                    <form onSubmit={handleForm} className="accountRightInner">
                        <div className="title">{_t("title.profile.info")}</div>
                        <div className="input-group">
                            <div className="input-child">
                                <label htmlFor="">{_t("forms_name_surname")}</label>
                                <input
                                    type="text"
                                    value={full_name}
                                    placeholder={_t("forms_name_surname")}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                {validator.message("full_name", full_name, "required")}

                            </div>
                            <div className="input-child">
                                <label htmlFor="">{_t("forms.numbers")}</label>
                                <InputMask
                                    mask="+\9\9\4999999999"
                                    maskChar=""
                                    value={phone}
                                    placeholder={_t("forms.numbers")}
                                    onChange={(e) => setPhone(e.target.value)}
                                    // disabled
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-child">
                                <label htmlFor="">{_t("forms.emails")}</label>
                                <input
                                    type="text"
                                    value={email}
                                    placeholder={_t("forms.emails")}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {validator.message("email", email, "email")}

                            </div>
                        </div>
                        {/* <div className="input-group">
              <div className="input-child">
                <label htmlFor="">Email</label>
                <input type="email" placeholder="Email adresinizi daxil edin" />
              </div>
              <div className="input-child resp-none"></div>
            </div> */}
                        <div className="accountSave">
                            <button>
                                <Tick/>
                                {_t("buttons.save")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default PersonalInfo;
