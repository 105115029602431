import React, { useContext, useEffect, useState } from "react";
import "./HeaderTop.scss";

// svg
import { ReactComponent as Instagram } from "../../../assets/instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/youtube.svg";
import { ReactComponent as Facebook } from "../../../assets/facebook.svg";
import ApiService from "../../../api/axios";
import { localeContext } from "../../Store";
const HeaderTop = (props) => {
  const [locale, setLocale] = useContext(localeContext);
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    ApiService.get("helpers/languages").then((resp) => {
      setLanguages(resp.data.data);
    });
  }, [locale]);
  const changeLocale = (e, language) => {
    ApiService.get(`helpers/locale/${language}`).then((resp) => {
      sessionStorage.setItem("locale", resp.data.data);
      setLocale(language);
      window.location.reload();
    });
  };
  return (
    <div className="headerTop">
      <div className="container">
        <div className="headerTopInner">
          <div className="headerInnerSocial">
            <a href={props.socials.instagram} target="_blank">
              <Instagram />
            </a>
            <a href={props.socials.youtube} target="_blank">
              <Youtube />
            </a>
            <a href={props.socials.facebook} target="_blank">
              <Facebook />
            </a>
          </div>
          <div className="lang">
            {languages.map((language) => {
              if (language.code != locale) {
                return (
                  <button
                    key={language.id}
                    onClick={(e) => {
                      changeLocale(e, language.code);
                    }}
                  >
                    {language.code}
                  </button>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
