import React, {useEffect, useState} from "react";
import "./Contact.scss";
import {Link} from "react-router-dom";

import {ReactComponent as Facebook} from "../../assets/facebook.svg";
import {ReactComponent as Instagram} from "../../assets/instagram.svg";
import {ReactComponent as Youtube} from "../../assets/youtube.svg";
import {ReactComponent as Phone} from "../../assets/phone.svg";
import {ReactComponent as Wp} from "../../assets/whatsapp.svg";
import {ReactComponent as Mail} from "../../assets/mail.svg";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import {_t} from "../../helpers/helpers";
import Swal from "sweetalert2";
import {ReactComponent as Pin} from "../../assets/Icon.svg";
import SimpleReactValidator from "simple-react-validator";
import InputMask from "react-input-mask";
import SupportSidebar from "../../components/SupportSidebar/SupportSidebar";

const Contact = () => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [pageMeta, setPageMeta] = useState([]);
    const [contact, setContact] = useState({});
    const [phone, setPhone] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");
    const [validator] = useState(new SimpleReactValidator({locale: localStorage.locale ? localStorage.locale : 'az'}));
    const [errors, setErrors] = useState({});

    const [contactDetail, setContactDetail] = useState({
        address: {
            addresses_description: "",
            addresses_maps: "",
            addresses_title: "",
        },
    });
    useEffect(() => {
        ApiService.get("pageByUrl?url=about").then((resp) => {
            setContact(resp.data.data);
            setPageMeta(resp.data.page_meta);
            setBreadcrumbs(resp.data.page_meta.breadcrumbs);
        });
        ApiService.get("helpers/settings").then((res) => {
            setContactDetail(res.data.data);
        });
    }, []);
    const handleContact = (e) => {
        e.preventDefault()
        if (validator.allValid()) {
            ApiService.post("/form/contact", {
                email: email,
                phone: phone,
                full_name: userName,
                message: text,
            }).then((res) => {
                Swal.fire(res.data.title, res.data.message, "success");
                e.target.reset();
            });
        } else {
            validator.showMessages();
            setErrors({...validator.getErrorMessages()});

        }
    };
    return (
        <div className="support">
            <div className="accoutRow">
                <div className="accountSidebar">
                    <div className="accountSideBarChild">
                        <Meta meta={pageMeta} />
                        <BreadCrumb breadcrumbs={breadcrumbs} />
                        <h1>{_t("title.support")}</h1>
                        <SupportSidebar />
                    </div>
                </div>
                <div className="AccountRight">
                    <div className="accountRightInner">
                        <div className="contact">
                            <div className="contactInner">
                                <div className="contactHeader">
                                    <div className="title">
                                        {_t("title.contact")}
                                        <span>
                      <Pin/>
                                            {contactDetail.address.addresses_description}
                  </span>
                                        <div className="contactDetail">
                                            <a href={`tel:${contactDetail.number}`}>
                                                <Phone/>
                                                {contactDetail.number}
                                            </a>
                                            <a href={contactDetail.wp_link} target="_blank">
                                                <Wp/>
                                                {contactDetail.number}
                                            </a>
                                            <a href={`mailto:${contactDetail.email}`}>
                                                <Mail/>
                                                {contactDetail.email}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="map">
                                <iframe src={contactDetail.address.addresses_maps}></iframe>
                            </div>
                            <br/>
                            <div className="title">
                                {_t("FRONTEND.CONTACT_FORM_TITLE")}
                                <span>{_t("title_question_for_us")} ?</span>
                            </div>
                            <form action="" onSubmit={handleContact}>
                                <div className="input-group user">
                                    <div className="input-child">
                                        <input
                                            type="text"
                                            name="full_name"
                                            placeholder={_t("forms_name_surname")}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                        {validator.message("full_name", userName, "required")}

                                    </div>
                                </div>
                                <div className="input-group phone">
                                    <div className="input-child">
                                        <InputMask
                                            mask="+\9\9\4999999999"
                                            maskChar=""
                                            name="phone"
                                            placeholder={_t("forms.numbers")}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                        {validator.message("phone", phone, "required")}

                                    </div>
                                </div>
                                <div className="input-group mail">
                                    <div className="input-child">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder={_t("forms.emails")}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {validator.message("email", email, "required|email")}

                                    </div>
                                </div>
                                <div className="input-group chat">
                                    <span>{_t("title.contact_form")}</span>
                                    <div className="input-child">
                                  <textarea
                                      name="message"
                                      id=""
                                      cols="30"
                                      rows="10"
                                      onChange={(e) => setText(e.target.value)}
                                  ></textarea>
                                        {validator.message("message", text, "required|max:255")}

                                    </div>
                                </div>
                                <div className="input-group">
                                    <button className="blackButton">{_t("buttons.send")}</button>
                                </div>
                            </form>
                            <div className="socialIcons">
                                <a href={contactDetail.instagram} target="_blank">
                                    <Instagram/>
                                </a>
                                <a href={contactDetail.youtube} target="_blank">
                                    <Youtube/>
                                </a>
                                <a href={contactDetail.facebook} target="_blank">
                                    <Facebook/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>

    )
        ;
};

export default Contact;
