import React, {useEffect, useState} from "react";
import "./AppOrWebsitePrompt.scss";
import Modal from 'react-modal';
import {ReactComponent as Globe} from '../../assets/globe.svg'

function AppOrWebsitePrompt({settings,isIos}) {
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const handleContinueWebsite = () => {
        // Handle action when user chooses to continue on the website
        setModalIsOpen(false);
        sessionStorage.setItem('isConnected', '1');
    };

    const handleContinueApp = () => {
        setModalIsOpen(false);
        sessionStorage.setItem('isConnected', '1');
        if (isIos) {
            window.location.href = 'https://apps.apple.com/az/app/toolino/id1670032034'; // Replace with your iOS app link
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.toolino'; // Replace with your Android app link
        }
    };


    // Initialize state using localStorage or a default value
    const [isConnected, setIsConnected] = useState(() => {
        const storedConnectValue = sessionStorage.getItem('isConnected');
        return storedConnectValue !== null ? JSON.parse(storedConnectValue) : false; // Default value if not set
    });

    // Update localStorage whenever the state changes

    return (
        <Modal
            isOpen={modalIsOpen}
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="App or Website Prompt"
        >
            <div onClick={()=>{setModalIsOpen(false)}} className="modal-header">
                <h2>Harada davam edəcəyinizi seçin</h2>
                <button>
                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.54289 9.54289C9.93342 9.15237 10.5666 9.15237 10.9571 9.54289L20.5 19.0858L30.0429 9.54289C30.4334 9.15237 31.0666 9.15237 31.4571 9.54289C31.8476 9.93342 31.8476 10.5666 31.4571 10.9571L21.9142 20.5L31.4571 30.0429C31.8476 30.4334 31.8476 31.0666 31.4571 31.4571C31.0666 31.8476 30.4334 31.8476 30.0429 31.4571L20.5 21.9142L10.9571 31.4571C10.5666 31.8476 9.93342 31.8476 9.54289 31.4571C9.15237 31.0666 9.15237 30.4334 9.54289 30.0429L19.0858 20.5L9.54289 10.9571C9.15237 10.5666 9.15237 9.93342 9.54289 9.54289Z" fill="#121212"></path></svg>
                </button>

            </div>
            <div className="buttons d-flex">
                <span onClick={handleContinueApp}>
                    <img style={{borderRadius:"15px"}} width="60" src="https://api.toolino.com/toolino.png"/>
                    <br/>

                    <h4>Application</h4>
                </span>
                <span onClick={handleContinueWebsite}>
                    <Globe/><br/>
                    <h4>Website</h4>
                </span>
            </div>
        </Modal>
    );
}

export default AppOrWebsitePrompt;
