import React, {useState, useEffect} from 'react';
import './LoanCalculator.css';
import {isNumber} from "chart.js/helpers";
import Modal from 'react-modal';

import {_t, calculateInterest} from "../../helpers/helpers";
import InputMask from "react-input-mask";
import ApiService from "../../api/axios";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import MonthlyPaymentsTable from "./MonthlyPaymentTable"; // Import CSS file for additional styling

const LoanCalculator = ({loanAmount, products}) => {
    const [loanTermMonths, setLoanTermMonths] = useState(0);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [maxMonths, setMaxMonths] = useState([3, 6]);
    const [full_name, setFullName] = useState("");
    const [initialPayment, setInitialPayment] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [interestPayment, setInterestPayment] = useState(0);
    const [interestRate, setInterestRate] = useState(0);
    const [hasWorkplace, setHasWorkplace] = useState(2);
    const [hasLegalWorkplace, setHasLegalWorkplace] = useState(2);
    const [chartData, setChartData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [validator] = useState(new SimpleReactValidator({locale: localStorage.locale ? localStorage.locale : 'az'}));
    const [errors, setErrors] = useState({});


    useEffect(() => {
        calculateMonthlyPayment();
        if (hasWorkplace == 1) {
            setMaxMonths([3, 6, 9, 12, 15, 18])
        }

    }, [loanTermMonths, initialPayment, loanAmount, hasWorkplace, hasLegalWorkplace]);

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const handleForm = (e) => {
        e.preventDefault();
        if (validator.allValid() && parseInt(monthlyPayment) > 0) {
            ApiService.post("/loan/application",
                {
                    full_name,
                    email,
                    phone,
                    loanAmount,
                    initialPayment,
                    monthlyPayment,
                    hasLegalWorkplace,
                    interestRate,
                    loanTermMonths,
                    products,
                }).then((resp) => {
                if (resp.data.success){
                    Swal.fire(_t("success.title"), _t("success.message"), "success");
                }else {
                    Swal.fire(resp.data.message, 'Xəta', "error");
                }
            });
        } else {
            validator.showMessages();
            setErrors({...validator.getErrorMessages()});
        }
        closeModal();
    };


    const calculateMonthlyPayment = () => {
        if (loanTermMonths > 0) {
            ApiService.post('/loan/calculate', {
                loanAmount, initialPayment, loanTermMonths, hasWorkplace, hasLegalWorkplace
            })
                .then((res) => {
                    setInterestRate(res.data.data.interestRate); // Convert back to annual rate for display
                    setMonthlyPayment(res.data.data.monthlyPayment);
                    setInterestPayment(res.data.data.interest_payment)
                    setTotalPayment(res.data.data.total_interest_payment)
                })
        }
    }


    return (
        <div style={{marginBottom:"100px"}}>
            <div className="loan-calculator-container">
                <div className="loan-calculator">
                    <div className="result-div">
                        {_t("forms.loan_amount")}:
                        <br/>
                        <span ><span
                            className="blue-text">{isNumber(loanAmount) ? loanAmount : 0}</span> AZN </span>
                    </div>
                    <div className="result-div">
                        {_t("forms.total_payment")}:
                        <span
                            className="big-text">
                            <span
                                className="blue-text">{parseInt(interestPayment)}</span> AZN
                        </span>
                    </div>


                    <div className="form-group">
                        <label>{_t("forms.has_workplace")}</label>
                        <select
                            className="select-field"
                            value={hasWorkplace}
                            onChange={(e) => setHasWorkplace(parseInt(e.target.value))}
                        >
                            <option value="1">{_t('forms.yes')}</option>
                            <option value="2">{_t('forms.no')}</option>
                        </select>
                    </div>

                    {hasWorkplace == 1 ? <div className="form-group">
                        <label>{_t("forms.legal_workplace")}</label>
                        <select
                            className="select-field"
                            value={hasLegalWorkplace}
                            onChange={(e) => setHasLegalWorkplace(parseInt(e.target.value))}
                        >
                            <option value="1">{_t('forms.yes')}</option>
                            <option value="2">{_t('forms.no')}</option>
                        </select>
                    </div> : ""}

                    {
                        parseInt(monthlyPayment) > 0
                            ?
                            <button className="apply-button" onClick={openModal}>{_t('buttons.apply')}</button>
                            : ""
                    }
                </div>
                <div className="chart-container">
                    <div className="result-group">
                        <div className="result-div">
                            {_t("forms.interest_rate")}:
                            <span className="big-text"> <span
                                className="blue-text">{parseInt(monthlyPayment) > 0 ? interestRate : 0}</span> %</span>
                        </div>
                        <div className="result-div">
                            {_t("forms.monthly_payment")}:
                            <span className="big-text"><span className="blue-text">
                                {isNumber(monthlyPayment) ? monthlyPayment : 0}
                            </span> AZN </span>
                        </div>
                        <div className="form-group">
                            <label>{_t("forms.initial_payment")}</label>
                            <input
                                className="input-field"
                                type="number"
                                value={initialPayment ?? 0}
                                onChange={(e) => {
                                    setInitialPayment(parseInt(e.target.value ?? 0));

                                }}
                                min="0"
                                max={loanAmount}
                            />
                        </div>

                        <div className="form-group">
                            <label>{_t("forms.loan_term_months")}</label>
                            <select
                                className="select-field"
                                value={loanTermMonths}
                                onChange={(e) => {
                                    setLoanTermMonths(parseInt(e.target.value));
                                }}
                            >
                                <option value="0">{_t("forms.loan_term_months")}</option>
                                {maxMonths.map((value) => {
                                    return (
                                        <option key={value} value={value}>{value + " " + _t("forms.months")}</option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>

                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="modal"
                    overlayClassName="overlay"
                >
                    <form onSubmit={handleForm} className="accountRightInner" style={{paddingRight: "10px"}}>
                        <div className="title">{_t("loan_info")}</div>
                        <div className="input-group">
                            <div className="input-child" style={{marginBottom: "10px"}}>
                                <input
                                    type="text"
                                    value={full_name}
                                    placeholder={_t("forms_name_surname")}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                {validator.message("full_name", full_name, "required")}

                            </div>
                            <div className="input-child">
                                <InputMask
                                    mask="+\9\9\4999999999"
                                    maskChar=""
                                    value={phone}
                                    placeholder={_t("forms.numbers")}
                                    onChange={(e) => setPhone(e.target.value)}
                                    // disabled
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-child">
                                <input
                                    type="text"
                                    value={email}
                                    placeholder={_t("forms.emails")}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {validator.message("email", email, "email")}

                            </div>
                        </div>
                        <div>
                            <button>
                                {_t("buttons.save")}
                            </button>
                        </div>
                    </form>
                </Modal>

            </div>
            {monthlyPayment>0?
                <MonthlyPaymentsTable
                    loanAmount={loanAmount}
                    initialPayment={initialPayment}
                    loanTermMonths={loanTermMonths}
                    totalPayment={totalPayment}
                    totalInterest={interestPayment}
                    interestRate={interestRate}
                    monthlyPayment={monthlyPayment} // Ensure monthlyPayment prop is passed
                    hasLegalWorkplace={hasLegalWorkplace} // Ensure monthlyPayment prop is passed
                    hasWorkplace={hasWorkplace} // Ensure monthlyPayment prop is passed
                />:''
            }
        </div>
    );
}

export default LoanCalculator;
