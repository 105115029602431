import React, { useEffect, useState } from "react";
import "../index.scss";
import SupportSidebar from "../../../components/SupportSidebar";
import BonusCard5 from "../../../assets/bonusCard5.png";
import BonusCard10 from "../../../assets/bonusCard10.png";
import BonusCard15 from "../../../assets/bonusCard15.png";
import BonusCard20 from "../../../assets/bonusCard20.png";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import BreadCrumb from "../../../components/BreadCrumb";
import { _t } from "../../../helpers/helpers";

const Bonus = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [bonus, setBonus] = useState({
    media: [
      {
        slider: "",
      },
    ],
  });
  const [bonusMedia, setBonusMedia] = useState([]);
  useEffect(() => {
    ApiService.get("pageByUrl?url=bonus").then((resp) => {
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setBonus(resp.data.data);
    });
  }, []);
  return (
    <div className="support">
      <div className="accoutRow">
        <div className="accountSidebar">
          <div className="accountSideBarChild">
            <Meta meta={pageMeta} />
            <BreadCrumb breadcrumbs={breadcrumbs} />
            <h1>{_t("title.support")}</h1>
            <SupportSidebar />
          </div>
        </div>
        <div className="AccountRight">
          <div className="accountRightInner">
            <div className="subTitle">{bonus.title}</div>
            <div className="bonusList">
              <div
                dangerouslySetInnerHTML={{
                  __html: bonus.content,
                }}
              />
            </div>
            <div className="bonusCards">
              {bonus.media.map((item) => {
                return (
                  <div key={item} className="img-container">
                    <img src={item.slider} alt="bonus card 5%" />
                  </div>
                );
              })}
              {/* <div className="img-container">
                <img src={BonusCard10} alt="bonus card 10%" />
              </div>
              <div className="img-container">
                <img src={BonusCard15} alt="bonus card 15%" />
              </div>
              <div className="img-container">
                <img src={BonusCard20} alt="bonus card 20%" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Bonus;
