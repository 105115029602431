import React, {useEffect, useState} from "react";
import "./Application.scss";
import {
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ApplicationLinks from "../../Footer/ApplicationLinks";
import ApiService from "../../../api/axios";
import ReactImageMagnify from "react-image-magnify";

function Application({ settings,isIos }) {
  const [application, setApplication] = useState({
    title_image: {},
  });

  useEffect(() => {
    ApiService.get("index/application").then((resp) => {
      setApplication(resp.data.content);
    });
  }, []);

  return (
    <div className="Application">
      <div className="container">
        <div className="row">
          <div className="xl-7 lg-7 md-7 sm-7">
            <div className="aboutContent">
              <h6>{application.description}</h6>
              <p>{application.title}</p>
              <ApplicationLinks isIos={isIos} settings={settings} />
            </div>
          </div>
          <div  className=" xl-5 lg-5 md-5 sm-5">

            <img width="650" src="https://api.toolino.com/frontend/images/toolino_last.png" alt=""/>

          </div>

        </div>
      </div>
    </div>
  );
}

export default trackWindowScroll(Application);
