import React, {useState} from "react";
import "./Footer.scss";
import {ReactComponent as AppStore} from "../../assets/app-store.svg";
import {ReactComponent as PlayStore} from "../../assets/google-play.svg";
import {ReactComponent as AppQrCode} from "../../assets/app_code.svg";

const ApplicationLinks = ({settings, isIos}) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    return (
        <>
            <div className="qr-code-section">
                <AppQrCode/>
            </div>
            <div className="store-links">
                <a href={settings.app_store_link} target="_blank">
                    <AppStore/>
                </a>
                <a className="playstore-link" href={settings.play_store_link} target="_blank">
                    <PlayStore/>
                </a>
            </div>
        </>
    );
};

export default ApplicationLinks;
