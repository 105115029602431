import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiService from "../../../api/axios";
import {ReactComponent as Arrow} from "../../../assets/arrow.svg";
import {ReactComponent as Manat} from "../../../assets/manatBlack.svg";
import AccountSideBar from "../../../components/AccountSideBar";
import BreadCrumb from "../../../components/BreadCrumb";
import Meta from "../../../components/Meta/Meta";
import {_t} from "../../../helpers/helpers";
import "../index.scss";
import Loading from "../../../assets/loading.gif";
import {Rating} from "react-simple-star-rating";
import {tokenContext} from "../../../components/Store";
import Swal from "sweetalert2";

const OrderHistory = () => {
    const [orderHistory, setOrderHistory] = useState([]);
    const [pageMeta, setPageMeta] = useState({});
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [is_end, setIsEnd] = useState(false);
    const [token, setToken] = useContext(tokenContext);
    const [activeIndex, setActiveindex] = useState(0);

    let history = useHistory();

    const handleActiveIndex = (index) =>{
        if (activeIndex==index){
            setActiveindex(0)
        }else {
            setActiveindex(index);
        }
    }
    const init = () => {
        setLoading(true);
        if(token){
            ApiService.get(`account/orders?page=${page}`,).then((resp) => {
                setOrderHistory((orderHistory) => ({
                    ...orderHistory,
                    ...resp.data.data,
                }));
                setPageMeta(resp.data.page_meta);
                setBreadcrumbs(resp.data.page_meta.breadcrumbs);
                setTotal(resp.data.total);
                setIsEnd(resp.data.is_end);
                setLoading(false)
            });

        }
    };

    useEffect(() => {
        init();
    }, [page]);
    const handleRating = (rate, id) => {
        ApiService.post(
            "account/rate",
            {star: rate, product_id: id},
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        ).then((res) => {
            Swal.fire(_t('rate.message'), '', "success");
        });
    };

    const reOrder = (id) => {
        Swal.fire({
            title: _t("reorder_question"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
        }).then((result) => {
            if (result.isConfirmed) {
                ApiService.post(
                    "order/user/repeatOrder",
                    {order_id: id},
                ).then((resp) => {
                    if (resp.data.success == true) {
                        history.push("/cart");
                    }
                });
            }
        });


    };


    const handleLoadMore = () => {
        setPage(page + 1)
    };
    return (
        <div className="orderHistory account">
            {/* <div className="container"> */}
            <div className="accoutRow">
                <Meta meta={pageMeta}/>
                <div className="accountSidebar">
                    <div className="accountSideBarChild">
                        <BreadCrumb breadcrumbs={breadcrumbs}/>
                        <h1>{_t("title.profile.info")}</h1>
                        <AccountSideBar/>
                    </div>
                </div>
                <div className="AccountRight">
                    <div className="accountRightInnerOrder">
                        <div className="title">{_t("pages.order_history.title")}</div>
                        <div className="orderHistory">
                            <div className="table">
                                <div className="ordername">{_t("title.order.number")}</div>
                                <div className="date">{_t("table.header.date")}</div>
                                <div className="summary">{_t("title.summery")}</div>
                                <div className="orderstatus">{_t("title.order.status")}</div>
                                <div className="paymentstatus">{_t("title.order.payment")}</div>
                                <div className="paymentstatus">{_t("button.repeat")}</div>
                            </div>

                            {Object.keys(orderHistory).map((key) => {
                                return (
                                    <Accordion  key={key} order={orderHistory[key]} expanded={activeIndex==key}>
                                        <AccordionSummary
                                            expandIcon={<Arrow onClick={()=> {
                                                handleActiveIndex(key)
                                            }}/>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <div className="contentDesk">
                                                <Typography sx={{width: "18%"}}>
                                                    {orderHistory[key][0].order_id}
                                                </Typography>
                                                <Typography sx={{width: "28%"}}>
                                                    {orderHistory[key][0].date}
                                                </Typography>
                                                <Typography sx={{width: "18%"}}>
                                                    {orderHistory[key][0].total_payment} AZN
                                                </Typography>
                                                <Typography sx={{width: "18%"}}>
                                                    {_t(orderHistory[key][0].status)}
                                                </Typography>
                                                <Typography sx={{width: "18%"}}>
                                                    <div className="notPaid">
                                                        {_t(orderHistory[key][0].payment_type)}
                                                    </div>
                                                </Typography>

                                                <Typography sx={{width: "18%"}}>
                                                    <button className="reorderButton"
                                                            onClick={() => reOrder(orderHistory[key][0].id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                                             viewBox="0 0 512 512">
                                                            <path
                                                                d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"/>
                                                        </svg>
                                                    </button>
                                                </Typography>
                                            </div>
                                            <div className="contentResp">
                                                <Typography>
                                                    <div className="contentRespClass">
                                                        <p>
                                                            {_t("title.order.number")}
                                                            <span> {orderHistory[key][0].order_id}</span>
                                                        </p>
                                                    </div>
                                                    <div className="contentRespHeight">
                                                        <div className="contentRespClass">
                                                            <p>
                                                                {_t("table.header.date")}
                                                                <span>{orderHistory[key][0].date}</span>
                                                            </p>
                                                        </div>
                                                        <div className="contentRespClass">
                                                            <p>
                                                                {_t("title.order.status")}

                                                                <span>{_t(orderHistory[key][0].status)}</span>
                                                            </p>
                                                        </div>
                                                        <div className="contentRespClass">
                                                            <p>
                                                                {_t("title.order.payment")}
                                                                <span className="notPaid">
                                    {_t(orderHistory[key][0].payment_type)}
                                  </span>
                                                            </p>
                                                        </div>
                                                        <div className="contentRespClass">
                                                            <p>
                                                                {_t("title.summery")}
                                                                <span>
                                    {orderHistory[key][0].total_payment}
                                                                    AZN
                                  </span>
                                                            </p>
                                                        </div>
                                                        <div className="contentRespClass">
                                                            <p>
                                                                {_t("title.order.payment")}                                                                <span className="notPaid">
                                    {_t(orderHistory[key][0].payment_status)}
                                  </span>
                                                            </p>
                                                        </div>

                                                        <div className="contentRespClass">
                                                            <p>
                                                                {_t("button.repeat")}

                                                                <span className="notPaid">
                                                                <button className="reorderButton" onClick={() => reOrder(orderHistory[key][0].id)}>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path
                                                                      d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z"/></svg>
                                                                </button>
                                                              </span>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </Typography>
                                            </div>
                                        </AccordionSummary>

                                        {orderHistory[key][0].order_items.map((item) => {
                                            return (
                                                <AccordionDetails key={item.id}>
                                                    <Typography>
                                                        <div className="img-container">
                                                            <img src={item.product.title_image} alt=""/>
                                                        </div>
                                                        <div className="detailDivParent">
                                                            <div className="detailDiv detailDivP">
                                                                <label htmlFor="">
                                                                    {_t("sidebar.product")}
                                                                </label>
                                                                <p>{item.product.title}</p>
                                                                <p>SKU: {item.product.sku}</p>
                                                                <div
                                                                    className="productCardRating orderHistory-rating">
                                                                    <Rating
                                                                        onClick={(rate) =>
                                                                            handleRating(rate, item.product.id)
                                                                        }
                                                                        initialValue={item.product.rating}
                                                                        fillColor={"#FF0000"}
                                                                        allowHalfIcon={true}
                                                                        iconsCount={5}
                                                                        size={15}
                                                                        ratingValue={item.product.rating} /* Available Props */
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="detailDiv detailDivChild">
                                                                <label htmlFor="">
                                                                    {_t("title.quantity")}
                                                                </label>
                                                                <span>
                                    {item.count} {_t("title.custom")}
                                  </span>
                                                            </div>
                                                            <div className="detailDiv detailDivChild">
                                                                <label htmlFor="">
                                                                    {_t("table.header.value")}
                                                                </label>
                                                                <div className="priceOrder">
                                                                    {parseFloat(item.product.price)}
                                                                    <Manat/>
                                                                </div>
                                                            </div>
                                                            <div className="detailDiv detailDivChild">
                                                                <label htmlFor="">{_t("title.total")}</label>
                                                                <div className="priceOrder">
                                                                    {parseFloat(item.count *
                                                                        parseFloat(item.product.price)).toFixed(2)}
                                                                    <Manat/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </AccordionDetails>
                                            );
                                        })}
                                        <div className="orderDelivery">
                                            <div className="orderDeliveryChild">
                                                {_t("label.total_order_amount")}:
                                                <span>{orderHistory[key][0].total_price}</span>
                                            </div>
                                            <div className="orderDeliveryChild">
                                                {_t("title.delivery")}:
                                                <span>{orderHistory[key][0].delivery}</span>
                                            </div>
                                            <div className="orderDeliveryChild">
                                                {_t("title.cart.bonus")}:
                                                <span>{orderHistory[key][0].used_bonuses}</span>
                                            </div>
                                            <div className="orderDeliveryChild totalPriceOrder">
                                                {_t("table.header.total_price")}:
                                                <span>{orderHistory[key][0].total_payment} AZN</span>
                                            </div>
                                        </div>
                                    </Accordion>
                                );
                            })}
                            <div className="loadingImg">

                                {loading ?
                                    <img src={Loading} alt="loading"/>
                                    : (is_end ? "" :
                                        <button className="load-more-btn"
                                                onClick={handleLoadMore}>{_t('load_more')}</button>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default OrderHistory;