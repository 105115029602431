import React, {useEffect, useState} from "react";
import About from "../../components/Homepage/About";
import Category from "../../components/Homepage/Category";
import ProductSlide from "../../components/Homepage/ProductSlider";
import ProductSlideSale from "../../components/Homepage/ProductSliderSale";
import TopInfoBar from "../../components/Homepage/TopInfoBar";
import TopSlider from "../../components/Homepage/TopSlider";

import ApiService from "../../api/axios";
import Banner from "../../components/Homepage/Banner";
import Brand from "../../components/Homepage/Brand";
import Meta from "../../components/Meta/Meta";
import Application from "../../components/Homepage/Application";

const Homepage = ({settings,isIos}) => {
    const [productSlide, setProductSlide] = useState([]);
    const [pageMeta, setPageMeta] = useState({});
    useEffect(() => {
        ApiService.get("index/products").then((resp) => {
            setProductSlide(resp.data.data);
        });
        ApiService.get("helpers/settings").then((res) => {
            setPageMeta(res.data.data);
        });
    }, []);
    return (
        <div className="sectionInner">
            <Meta meta={pageMeta}/>
            <TopSlider settings={settings}/>
            <TopInfoBar/>
            <About/>
            <Application isIos={isIos} settings={settings}/>
            <div className="categoryHome">
                <Category/>
            </div>
            <ProductSlideSale/>
            {Object.keys(productSlide).map((key) => {
                return <ProductSlide item={key} slide={productSlide[key]}/>;
            })}
            <Banner/>
            <Brand/>
            <div className="seoParent">
                <div className="container">
                    <div className="seoChild">
                        <h2>{pageMeta.meta_title}</h2>
                        <p>{pageMeta.meta_description}</p>
                        <div
                            // dangerouslySetInnerHTML={{
                            //   __html: pageMeta.content,
                            // }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
