import React, {useState, useEffect} from 'react';
import './LoanCalculator.css';
import ApiService from "../../api/axios";
import {_t} from "../../helpers/helpers";
const MonthlyPaymentsTable = ({
                                  loanAmount,
                                  initialPayment,
                                  loanTermMonths,
                                  interestRate,
                                  monthlyPayment,
                                  hasLegalWorkplace,
                                  hasWorkplace,
                                  totalPayment,
                                  totalInterest,
                              }) => {
    const [monthlyPayments, setMonthlyPayments] = useState([]);

    useEffect(() => {
        calculateMonthlyPayments();
    }, [loanAmount, initialPayment, loanTermMonths, interestRate, monthlyPayment, hasLegalWorkplace]);

    const calculateMonthlyPayments = () => {
        ApiService.post('/loan/table', {
            loanAmount,
            initialPayment,
            interestRate,
            hasLegalWorkplace,
            hasWorkplace,
            loanTermMonths,
            monthlyPayment
        }).then((res) => {
            console.log(res.data.data)
            setMonthlyPayments(res.data.data);
        })
    }

    return (
        <div className="table-container">
            <table className="payments-table">
                <thead>
                <tr>
                    <th>{_t('#')}</th>
                    <th>{_t('forms.months')}</th>
                    <th>{_t('forms.monthly_payment')}</th>
                    <th>{_t('forms.remaining_amount')}</th>
                </tr>
                </thead>
                <tbody>
                 {monthlyPayments.map((payment, index) => (
                     <tr key={index}>
                         <td>{payment.index}</td>
                         <td>{payment.month}</td>
                         <td>{monthlyPayment} AZN</td>
                         <td>{payment.remained_loan} AZN</td>
                     </tr>
                 ))}

                    </tbody>
                <tfoot>
                <tr>
                    <td>{_t('forms.total')}</td>
                    <td>{loanTermMonths} {_t('forms.months')}</td>
                    <td>{parseFloat(loanAmount)+parseFloat(totalPayment)} AZN</td>
                    <td>-</td>
                </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default MonthlyPaymentsTable;
