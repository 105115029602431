
import React, { useEffect } from "react";
import HeaderTop from "../HeaderTop";
import HeaderMiddle from "../HeaderMiddle";
import HeaderBottom from "../HeaderBottom";
const Header = ({ settings }) => {
  return (
    <header className="header">
      <HeaderTop
        socials={{
          facebook: settings.facebook,
          instagram: settings.instagram,
          youtube: settings.youtube,
        }}
      />
      <HeaderMiddle
        number={{ phone: settings.number, wp_link: settings.wp_link }}
      />
      <HeaderBottom
        socials={{
          facebook: settings.facebook,
          instagram: settings.instagram,
          youtube: settings.youtube,
        }}
        number={{ phone: settings.number, wp_link: settings.wp_link }}
      />
    </header>
  );
};

export default Header;
