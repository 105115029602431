import React, { useEffect, useState } from "react";
import "./NewsDetail.scss";
import "../News/News.scss";
import NewsWallp from "../../assets/newss.png";
import { ReactComponent as Time } from "../../assets/Time.svg";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import { ReactComponent as Fb } from "../../assets/fb.svg";
import { ReactComponent as Tw } from "../../assets/tw.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Navigation } from "swiper";
import { ReactComponent as Arrow } from "../../assets/arrowRight.svg";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import ApiService from "../../api/axios";
import Swal from "sweetalert2";
import { _t } from "../../helpers/helpers";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { Comments, FacebookProvider, Share as FbShare } from "react-facebook";
import ShareLink from "react-twitter-share-link";

const NewsDetail = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  // const [alias, setAlias] = useState(props.match.params.alias);
  const [newsDetail, setNewsDetail] = useState({
    title_image: "",
  });
  const [excerpts, setExcerpts] = useState([]);
  useEffect(() => {
    let alias = props.match.params.alias;

    ApiService.get(`news/detailsByUrl/${alias}`).then((resp) => {
      setNewsDetail(resp.data.content);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setExcerpts(resp.data.excerpts);
    });
  }, [props.match.params.alias]);

  return (
    <div className="newsDetail">
      <Meta meta={pageMeta} />

      <div className="news-wallp resp-none">
        <img src={newsDetail.title_image} alt="" />
      </div>
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="newsDetailTop">
          <div className="row">
            <div className="xl-5 lg-5 md-5 sm-12">
              <h1>{newsDetail.title}</h1>
              <div className="newsDetailHead">
                <div className="newsdetailCard">
                  <Time /> {newsDetail.dateHuman}
                </div>
                {/* <div className="newsdetailCard">
                  <Eye />
                  201
                </div> */}
                <div className="newsdetailCard"></div>
              </div>
            </div>
            <div className="xl-12 lg-12 md-12 sm-12">
              <div className="news-wallp desk-none">
                <img src={newsDetail.title_image} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="newsDetailContent">
          <div className="newsDetailInner">
            <div className="title">{newsDetail.title}</div>
            <p
              dangerouslySetInnerHTML={{
                __html: newsDetail.content,
              }}
            />
            {/* <ul>
              <li >
                Each one was more amazing than the last. It was at this moment
                when I fell completely head over heels for Italy. What a
                beautiful country! Rome was l
              </li>
            </ul> */}

            <div className="img-container">
              <img src={newsDetail.title_image} alt="news img" />
            </div>
            {/* <div className="title">
              Bilirsiniz mi, ev alətlərinin istifadəsi zamanı ən çox nələrə
              üstünlük verilir?
            </div>
            <p>
              In San Marino, we wanted to bask in the beautiful mountainous area
              but also the historic old buildings. The Castle was a sight to
              behold, especially at night. And from San Marino, we stopped by
              San Gimignano. Tuscany is a beautiful area, and it would have been
              a crimeWe made a few more stops before we arrived in Florence,
              namely: Rome, San Marino, and San Gimignano. We made a few more
              stops before we arrived in Florence, namely: Rome, San Marino, and
              San Gimignano.
            </p>
            <p>
              In San Marino, we wanted to bask in the beautiful mountainous area
              but also the historic old buildings. The Castle was a sight to
              behold, especially at night. And from San Marino, we stopped by
              San Gimignano. Tuscany is a beautiful area, and it would have been
              a crimeWe made a few more stops before we arrived in Florence,
              namely: Rome, San Marino, and San Gimignano. We made a few more
              stops before we arrived in Florence, namely: Rome, San Marino, and
              San Gimignano.
            </p>
            <div className="img-container">
              <img src={NewsWallp} alt="news img" />
            </div> */}
            {/* <div className="title">
              Bilirsiniz mi, ev alətlərinin istifadəsi zamanı ən çox nələrə
              üstünlük verilir?
            </div>
            <div className="newsInnerDetail">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
              metus facilisi elementum amet.Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Urna metus facilisi elementum
              amet.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
              metus facilisi elementum amet.Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Urna metus facilisi elementum amet.
            </div> */}
            <div className="shareNews">
              <span>{_t("frontend.share_items")}</span>
              {/* <Link to="/">
                <Share />
              </Link>
              <Link to="/">
                <Fb />
              </Link>
              <Link to="/">
                <Tw />
              </Link> */}
              <CopyToClipboard
                onCopy={() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: _t("message.copied"),
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }}
                text={window.location.href}
              >
                <a href="#">
                  <Share />
                </a>
              </CopyToClipboard>
              <FacebookProvider appId="348228100426228">
                <FbShare href={window.location.href}>
                  {({ handleClick, loading }) => (
                    <a href="#">
                      <Fb
                        type="button"
                        disabled={loading}
                        onClick={handleClick}
                      />
                    </a>
                  )}
                </FbShare>
              </FacebookProvider>
              <ShareLink link={window.location.href}>
                {(link) => (
                  <a href={link} target="_blank">
                    <Tw />
                  </a>
                )}
              </ShareLink>
            </div>
          </div>
        </div>
        <div className="newsCardHeader">
          <div className="title">Digər xəbərlər</div>
          <div className="customNav">
            <button className="custom_prev custom_prev1">
              <Arrow />
            </button>
            <button className="custom_next custom_next1">
              <Arrow />
            </button>
          </div>
        </div>
        <Swiper
          navigation={{
            nextEl: ".custom_next1",
            prevEl: ".custom_prev1",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {excerpts.map((item) => {
            return (
              <SwiperSlide>
                <Link to={`/blog/${item.alias}`} className="newsSlide">
                  <div className="newsCard left">
                    <div className="news-card-inner">
                      <div className="time">
                        <Time />
                        {item.dateHuman}
                      </div>
                      <h2>{item.title}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      />
                    </div>
                  </div>
                  <div className="img-container">
                    <img src={item.title_image} alt="news" />
                    <div className="layerNews">
                      {_t("BUTTONS.MOREDETAIL")}
                      <Arrow />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="moreParent desk-none">
          <Link to="/blog" className="more">
            {_t("FRONTEND.PRODUCTS_EXCERPT")}
            <Arrow />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
