import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { localeContext } from "../Store";
const Meta = (props) => {
  const [locale, setLocale] = useContext(localeContext);
  return (
    <Helmet htmlAttributes>
      <meta charSet="utf-8" />
      <title>
        {/* {props.meta.meta_title == undefined ? (
          `${props.meta.meta_title} | Toolino`
        ) : (
          <>"Toolino"</>
        )} */}

        {`${props.meta.meta_title} | Toolino`}
      </title>
      <meta name="description" content={props.meta.meta_description} />
      <meta name="keywords" content={props.meta.meta_keywords} />
      <meta property="og:title" content={props.meta.meta_title} />
      <meta property="og:description" content={props.meta.meta_description} />
      <meta property="og:image" content={props.meta.title_image} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  );
};
export default Meta;
