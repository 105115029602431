import ApiService from "../api/axios";

export function emailValidation(email) {
    const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(regex.test(email) == false);
}


export function deleteCartItem(product_id) {
    return localStorage.token ?
        ApiService.delete("cart/user/delete", {
            params:
                {
                    "product_id": product_id,
                }
        }) :
        ApiService.delete("cart/guest/delete", {
            params:
                {
                    "guest_uid":
                    localStorage.guest_uid,
                    "product_id":
                    product_id,
                }
        });
}


export const addToCartItem = (product_id, count) => {
    return localStorage.token ?
        ApiService.post("cartNew/user/create", {
            product_id,
            count
        })
        :
        ApiService.post("cartNew/guest/create", {
            guest_uid: localStorage.guest_uid,
            product_id,
            count
        })
};



export const getTotalPrice = () => {
    localStorage.token ?
        ApiService.get("cart/user/price")
            .then(resp => {
                return resp.data.total_price;
            })
        :
        ApiService.get("cart/guest/price", {params: {guest_uid: localStorage.guest_uid}})
            .then(resp => {
                return resp.data.total_price
            });

}

export const getTotalCount = () => {
    let count = 0;
    localStorage.token ?
        ApiService.get("cart/user/total")
            .then(resp => {
                count = resp.data.total_count;
            })
        :
        ApiService.get("cart/guest/total", {params: {guest_uid: localStorage.guest_uid}})
            .then(resp => {
                count = resp.data.total_count;
            });
    return count;
}

export function getLocale() {
    return sessionStorage.getItem("locale") && "en";
}

export function setTranslates() {
    ApiService.get("helpers/translates").then((resp) => {
        // if (!localStorage.translates) {
        // }
        sessionStorage.setItem("translates", JSON.stringify(resp.data.data));
    });
}

export function _t(keyword, params = [], locale = sessionStorage.locale) {
    let translates = sessionStorage.translates;

    if (translates) {
        if (
            JSON.parse(translates) &&
            JSON.parse(translates)[locale] &&
            JSON.parse(translates)[locale][keyword]
        ) {
            return JSON.parse(translates)[locale][keyword][0]["value"];
        }
        return keyword;
    }
}

export function normalizeLocalStorage(object, field1, field2) {
    let items = [];
    for (let key in object) {
        items.push({
            value: object[key][field1],
            label: object[key][field2].toUpperCase(),
        });
    }
    return items;
}

export function getApiUrl() {
    let baseURL = "";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://127.0.0.1:8000/";
    } else if (process.env.NODE_ENV === "production") {
        baseURL = "";
    }
    return baseURL;
}
