import React, { useEffect, useState } from "react";
import "./TopSlider.scss";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay, Zoom} from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import Application from "../Application";

const TopSlider = ({settings}) => {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    ApiService.get("index/sliders").then((resp) => {
      setSliders(resp.data.data);
    });
  }, []);
  return (
    <div className="TopSlider">
      <div className="container">
        <Swiper
          direction={"vertical"}
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          spaceBetween={10}
          pagination={{ clickable: true }}
          breakpoints={{
            767: {
              direction: "vertical",
            },
            320: {
              direction: "horizontal",
            },
          }}
        >

          {sliders.map((slide) => {
            return (
                <SwiperSlide
                    style={{ background: `url(${slide.title_image})` }}
                    key={slide.id}
                >
                    <div className="row">
                        <div className="xl-7 lg-7 md-7 sm-6"></div>
                        <div className="xl-5 lg-5 md-5 sm-6">
                            <div className="sliderInner">
                                {slide.title == null ? null : (
                                    <h4 className="topSliderTitle">{slide.title}</h4>
                                )}

                                {slide.description == null ? null : (
                                    <p>{slide.description}</p>
                                )}

                                {slide.link == null ? null : (
                                    <Link
                                        href={slide.link}
                                        target="_blank"
                                        className="redButton"
                                    >
                                        {_t("buttons.order")}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

            );
          })}
          <div class="swiper-pagination"></div>
        </Swiper>
      </div>
    </div>
  );
};

export default TopSlider;
