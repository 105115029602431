import React, { useState, useEffect, useContext } from "react";
import ApiService from "../../../api/axios";
import InputMask from "react-input-mask";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import { ReactComponent as Cross } from "../../../assets/Cross.svg";
import { ReactComponent as Plus } from "../../../assets/add.svg";
import { tokenContext } from "../../../components/Store";
import { _t } from "../../../helpers/helpers";

const Address = (props) => {
  const [isToggleOn, setIsToggleOn] = useState("");
  const [address_name, setAddress_name] = useState("");
  const [full_address, setFull_address] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [token, setToken] = useContext(tokenContext);

  const [validator] = useState(new SimpleReactValidator({ locale: localStorage.locale?localStorage.locale:'az' }));
  const [errors, setErrors] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    ApiService.get("account/address/list", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((respa) => {
      setAddressList(respa.data.data);
      respa.data.data.map((value) => {
        if (value.is_default == 1) {
          props.selected(value.id);
        }
      });
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    document.body.classList.toggle("body-overflow");
    setIsToggleOn(!isToggleOn);
  };
  const handleClose = (e) => {
    document.body.classList.remove("body-overflow");
    setIsToggleOn(false);
  };
  const handleAddressName = (e) => {
    const addressname = e.target.value;
    setAddress_name(addressname);
  };
  const handleFullAddress = (e) => {
    const fulladdress = e.target.value;
    setFull_address(fulladdress);
  };
  const handleCity = (e) => {
    const city = e.target.value;
    setCity(city);
  };

  const handlePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      let payload = {};
      payload["address_name"] = address_name;
      payload["full_address"] = full_address;
      payload["city"] = city;
      payload["phone"] = phone;
      ApiService.post("account/address/add", payload, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((resp) => {
        Swal.fire(resp.data.title, "Successfuly added", "success").then(
          (resp) => {
            init();
            setIsToggleOn(false);
          }
        );
        document.body.classList.remove("body-overflow");
        setAddress({
          full_address: "",
          address_name: "",
          phone: "",
          city: "",
        });
        setIsToggleOn(false);
      });
    } else {
      validator.showMessages();
      setErrors({ ...validator.getErrorMessages() });
    }
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiService.delete(`account/address/delete/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((resp) => {
          Swal.fire(resp.data.title, resp.data.message, "success");
          init();
        });
      }
    });
  };
  const handleDefault = (e, id) => {
    e.preventDefault();
    ApiService.put(`account/address/default/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((resp) => {
      init();
      props.selected(id);
    });
  };

  return (
    <div className="address">
      {isToggleOn ? (
        <div className="popup">
          <div className="popupContainer">
            <form className="addressPopup" onSubmit={handleSubmit}>
              <button className="close" onClick={handleClose}>
                <Cross />
              </button>
              <div className="input-group">
                <div className="input-child">
                  <label htmlFor="">{_t("forms.addresses_name")}</label>
                  <input
                    type="text"
                    name="address_name"
                    value={address_name}
                    onChange={handleAddressName}
                  />
                  {validator.message("address_name", address_name, "required")}
                </div>
              </div>
              <div className="input-group">
                <div className="input-child">
                  <label htmlFor="">{_t("forms.full_address")}</label>
                  <input
                    type="text"
                    value={full_address}
                    onChange={handleFullAddress}
                  />
                  {validator.message("full address", full_address, "required|min:6")}
                </div>
              </div>
              <div className="input-group">
                <div className="input-child">
                  <label htmlFor="">{_t("forms.city")}</label>
                  <input type="text" value={city} onChange={handleCity} />
                  {validator.message("city", city, "required")}
                </div>
              </div>
              <div className="input-group">
                <div className="input-child">
                  <label htmlFor="">{_t("forms.numbers")}</label>
                  <InputMask
                    mask="+\9\9\4999999999"
                    maskChar=""
                    value={phone}
                    onChange={handlePhone}
                  />
                  {validator.message("phone", phone, "required")}
                </div>
              </div>
              <div className="input-group">
                <div className="input-child">
                  <button type="submit" className="blackButton">
                    {_t("buttons.create_address")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="xl-6 lg-6 md-6 sm-12">
          <button className="addressAddCard" onClick={handleClick}>
            <h4>{_t("create.address")}</h4>
            <Plus />
          </button>
        </div>
        {addressList.map((addres) => (
          <div className="xl-6 lg-6 md-6 sm-12">
            <label htmlFor="" className="addressCard">
              <div className="addressCardInner">
                <h2 className="title">
                  {_t("forms.addresses_name")}
                  <p>{addres.address_name}</p>
                </h2>
                <h2 className="title">
                  {_t("forms.full_address")}
                  <p>{addres.full_address}</p>
                </h2>
                <div className="addressCardİnnerDiv">
                  <h2 className="title">
                    {_t("forms.city")}
                    <p>{addres.city}</p>
                  </h2>
                  <h2 className="title">
                    {_t("forms.numbers")}
                    <p>{addres.phone}</p>
                  </h2>
                </div>
              </div>
              <div className="addressCardInnerBottom">
                <div>
                  <label htmlFor="">
                    <p>{addres.address_name}</p>
                    <input
                      type="radio"
                      name="address"
                      onClick={(e) => handleDefault(e, addres.id)}
                      checked={addres.is_default ? "checked" : null}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="buttons">
                  <button
                    type="button"
                    onClick={(e) => handleDelete(e, addres.id)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M3.125 5H4.16667H16.875"
                          stroke="#100718"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M6.66602 5.00045V3.33378C6.66602 2.89175 6.84161 2.46783 7.15417 2.15527C7.46673 1.84271 7.89066 1.66711 8.33268 1.66711H11.666C12.108 1.66711 12.532 1.84271 12.8445 2.15527C13.1571 2.46783 13.3327 2.89175 13.3327 3.33378V5.00045M15.8327 5.00045V16.6671C15.8327 17.1091 15.6571 17.5331 15.3445 17.8456C15.032 18.1582 14.608 18.3338 14.166 18.3338H5.83268C5.39066 18.3338 4.96673 18.1582 4.65417 17.8456C4.34161 17.5331 4.16602 17.1091 4.16602 16.6671V5.00045H15.8327Z"
                          stroke="#100718"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M8.33398 9.16711V14.1671"
                          stroke="#100718"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M11.666 9.16711V14.1671"
                          stroke="#100718"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Address;
