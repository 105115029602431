import React, { useEffect, useState } from "react";
import "./Compare.scss";
import { ReactComponent as Swap } from "../../assets/arrowSwap.svg";
import { ReactComponent as Trash } from "../../assets/Trash.svg";
import { ReactComponent as Cart } from "../../assets/cartt.svg";
import ProductCard from "../../components/ProductCard";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import BreadCrumb from "../../components/BreadCrumb";
import { _t } from "../../helpers/helpers";
import { Link } from "react-router-dom";
import ProductEmpty from "../../assets/productEmpty.png";
import { addToCompare } from "../../helpers/functions";

const Compare = () => {
  const [compareList, setCompareList] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState([]);
  const [category, setCategory] = useState({
    attributes_list: {},
  });
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    ApiService.get("account/comparelist").then((resp) => {
      setCompareList(resp.data.data);
      setCategory(resp.data.category);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setAttributes(resp.data.attributes_list);
    });
  }, []);

  function handleDelete(id) {
    let newList = compareList.filter((item) => item.id !== id);
    setCompareList(newList);
  }

  return (
    <div className="compare productsPage">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="compareHeader">
          <div className="title">
            <Swap />
            {_t("title.compare.products")}
          </div>
          <div className="compareHeaderRight">
            {/* <button type="button" onClick={() => handleRemove(product.id)}>
              <Trash />
              Bütün məhsulları silin
            </button> */}
            <Link to="/products" className="red">
              <Cart />
              {_t("title.add_product")}
            </Link>
          </div>
        </div>

        <div className="compareHead">
          <div className="CompareTItle">
            {compareList == 0 ? null : (
              <>
                <div style={{marginTop:"109px"}} className="compareSpecs">
                  <div className="result compareCard">
                    {_t("title.added_product")}
                  </div>
                  <div className="title">{_t("title.general_specs")}</div>
                  <div className="compareTable">
                    {Object.keys(attributes).map((key) => {
                      return (
                        <div className="div">{_t(`attributes.${key}`)}</div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="compareHeadChild productsParent">
            {compareList == 0 ? (
              <div className="cartEmpty">
                <div className="cartEptyContent">
                  <img src={ProductEmpty} alt="empty" />
                  <p>{_t("no.product.compare")}</p>
                </div>
              </div>
            ) : (
              compareList.map((product, index) => {
                return (
                  <div key={index} className="compareCard">
                    <button
                      type="button"
                      className="compareDelete"
                      onClick={(e) => {
                        addToCompare(e, product.id);
                        handleDelete(product.id);
                      }}
                    >
                      <Trash />
                    </button>
                    <ProductCard product={product} />
                    <div className="compareTable">
                      {Object.keys(product.attributes_list).map(
                        (key, index) => {
                          return (
                            <div className="div">
                              <span>{_t(`attributes.${key}`)}</span>
                              {product.attributes_list[key]}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compare;
